import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FrameDto, SnippetDto } from '../ml-data-types';

export type DataItem = FrameDto | SnippetDto;

@Component({
  selector: 'app-sort-control',
  templateUrl: './sort-control.component.html',
  styleUrls: ['./sort-control.component.sass'],
  standalone: true,
})
export class SortControlComponent {
  @Input()
  sortOptions: string[] = [];

  currentOptionIndex = 0;

  @Output()
  onSortOptionUpdate = new EventEmitter<string>();

  nextOption() {
    this.currentOptionIndex++;
    if (this.currentOptionIndex >= this.sortOptions.length) {
      this.currentOptionIndex = 0;
    }
    this.onSortOptionUpdate.emit(this.sortOptions[this.currentOptionIndex]);
  }
}
