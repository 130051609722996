<div>
  <mat-toolbar>
    <span style="margin: 0 auto">{{ operationDisplayName }}</span>
  </mat-toolbar>

  <app-handover-location-title-splitter title="Pickups" />

  <div class="grid">
    @for (pickup of pickups; track pickup) {
      <div>
        <button
          mat-raised-button
          class="select-button"
          [routerLink]="[pickup.name]"
          queryParamsHandling="true"
        >
          {{ pickup.displayName || pickup.name }}
        </button>
      </div>
    }
  </div>

  <app-handover-location-title-splitter title="Dropoffs" />

  <div class="grid">
    @for (dropoff of dropoffs; track dropoff.name) {
      <div>
        <button
          mat-raised-button
          class="select-button"
          [routerLink]="[dropoff.name]"
          queryParamsHandling="true"
        >
          {{ dropoff.displayName || dropoff.name }}
        </button>
      </div>
    }
  </div>
</div>
