<h1 mat-dialog-title>{{ data.dialogTitle ?? 'Select Operation' }}</h1>
<div mat-dialog-content>
  <mat-form-field appearance="fill" class="filter-dropdown">
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter()" [(ngModel)]="textFilter" #input />
  </mat-form-field>

  <div class="table-container">
    <table
      mat-table
      [dataSource]="filteredOperations"
      multiTemplateDataRows
      class="mat-elevation-z8"
    >
      <ng-container matColumnDef="displayName">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let operation">
          {{ operation.displayName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>ID</th>
        <td mat-cell *matCellDef="let operation">
          {{ operation.id }}
        </td>
      </ng-container>
      <ng-container matColumnDef="accessGroups">
        <th mat-header-cell *matHeaderCellDef>Access Groups</th>
        <td mat-cell *matCellDef="let operation">
          <mat-chip-list>
            @for (accessGroup of operation.accessGroups; track accessGroup) {
              <mat-chip>{{ accessGroup }}</mat-chip>
            }
          </mat-chip-list>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let operation; columns: columnsToDisplay"
        [class.selected]="operation.id === data.selectedOperationId"
        (click)="selectOperation(operation)"
      ></tr>
    </table>
  </div>
</div>
