@if (!selectedFrame) {
  <div class="collection-selection-title">Collection Info</div>
} @else {
  <div class="collection-selection-title">Frame Info</div>
}
<mat-divider />

<div>
  <app-selected-item
    class="collection-selection-item-container"
    style="display: contents"
    [metadataItem]="selectedFrame ?? collection"
    [selectedItem]="selectedFrame ?? collection"
    [previewImage]="selectedFrame?.url"
  >
  </app-selected-item>
</div>

<mat-divider />

@if (!selectedFrame) {
  <div class="collection-selection-title">Datasets</div>
}

<mat-divider />

@if (!selectedFrame) {
  <div>
    <div class="collection-dataset-list-container">
      @for (datasetSyncConfig of datasetSyncConfigs; track datasetSyncConfig) {
        <div class="collection-selection-title">
          {{ datasetSyncConfig.datasetName }}

          <mat-icon
            class="delete-dataset-button"
            (click)="deleteCollectionFromDataset(datasetSyncConfig.id)"
            >clear</mat-icon
          >
        </div>
      }
    </div>
    <mat-divider />

    <div class="collection-dataset-input-padding">
      <input
        matInput
        placeholder="Dataset name"
        [value]="datasetName"
        (input)="updateDatasetName($event.target.value)"
        class="collection-add-dataset-input"
      />

      <selection-dropbox
        class="collection-add-dataset-type-input"
        label="Label types"
        [options]="labelNames"
        [(selected)]="selectedLabelName"
        (selectedChange)="updateInput()"
        [customInput]="true"
      />
    </div>
    <div class="add-dataset-button">
      @if (isDataComplete) {
        <button mat-icon-button (click)="addCollectionToDataset()">
          <mat-icon>add</mat-icon>
        </button>
      }
    </div>
  </div>
}

<mat-divider />

@if (!selectedFrame) {
  <div class="collection-selection-title">Additional Properties</div>
}

<mat-divider />

@if (!selectedFrame) {
  <div>
    <mat-slide-toggle
      class="mat-slide-toggle-margin"
      [checked]="collection.locked"
      (change)="setCollectionLocked($event.checked)"
      >Locked</mat-slide-toggle
    >
  </div>
}
