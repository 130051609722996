import {
  EdgeElement,
  ElementType,
  GeoPoint,
  LineStringGeometry,
  LocalizationMapTiles,
  MapElement,
  PointGeometry,
  PolygonGeometry,
  SlippyTiles,
} from '@cartken/map-types';
import { BehaviorSubject, Observable, Subject, distinct } from 'rxjs';
import { getMidPoint } from '../../../utils/geo-tools';
import {
  generateBlockedEdgePoints,
  generateCorridorPolygon,
  generateOnewayArrows,
} from '../map-elements/edge';
import {
  LayerName,
  getHighlightColor,
  getIcon,
  getLineColor,
  slippyTilesStyles,
  getCorridorColor,
} from './visualization-styles';
import { Layer, LayerContext } from '@deck.gl/core';
import { IconLayer, SolidPolygonLayer, TextLayer } from '@deck.gl/layers';
import { InteractiveGeoJsonLayer } from './interactive-geojson-layer';
import { EditAction } from './types';
import { InteractiveMode } from './interactive-mode';
import { BaseMap, MapStyle } from './base-map';
import { MapboxBaseMap } from './mapbox-base-map';
import { createTileLayer, getBounds, isEdgeBlocked } from './utils';
import { computeHeading, LatLngBounds } from 'spherical-geometry-js';
import { isDefined } from '../../../utils/typeGuards';
import { TileLayer } from '@deck.gl/geo-layers';
import { LocalizationMapTileLayer } from './localization-map/localization-map-tile-layer';
import { LocalizationTileset } from './localization-map/localization-tileset';
import { ZoomBoundedLayer } from './zoom-bounded-layer';
import { IconMapping } from '@deck.gl/layers/dist/icon-layer/icon-manager';
import { createIconAtlas } from './icon-atlas';
import { Matrix4 } from '@math.gl/core';
import { NumberInput } from '@angular/cdk/coercion';

export interface MapElementMouseEvent {
  mapElementId: number;
  vertex?: number;
}

export interface MapElementGeometryChangeEvent {
  mapElementId: number;
  geometry: PointGeometry | LineStringGeometry | PolygonGeometry;
}

const altitudeFlatteningFactor = 1e-4;
const zoomThreshold = 18;

const { min, max, floor, ceil } = Math;

export class VisualizationManager {
  private readonly map: BaseMap;
  private _onChange$ = new Subject<MapElement[]>();
  private _selectedMapElement$ = new Subject<MapElement | undefined>();
  private _altitudeRange$ = new BehaviorSubject<[number, number]>([0, 100]);
  private layerStyles = structuredClone(slippyTilesStyles);
  private hiddenMapElementTypes: ElementType[] = [];
  private mapElementOpacity = 1;
  private zoom = Infinity;
  private flattenAltitudes = true;
  private minDisplayAltitude = 0;
  private maxDisplayAltitude = 1000;
  private altitudeOffset = 0;
  private hoveringOverObject = false;
  private interactiveMapElements: MapElement[] = [];
  private displayMapElements: MapElement[] = [];
  private localizationMapElements: LocalizationMapTiles[] = [];
  private slippyTileMapElements: SlippyTiles[] = [];
  private mode: InteractiveMode = new InteractiveMode();

  readonly onChange$ = this._onChange$.asObservable();
  readonly boundsChanged$: Observable<LatLngBounds>;
  readonly selectedMapElement$ = this._selectedMapElement$.asObservable();
  readonly altitudeRange$ = this._altitudeRange$.pipe(distinct());

  readonly icons = createIconAtlas();

  private selectedMapElement: MapElement | undefined;

  constructor(mapContainerElement: HTMLElement) {
    this.map = new MapboxBaseMap(mapContainerElement);
    this.map.setProps({
      getCursor: () => (this.hoveringOverObject ? 'pointer' : 'grab'),
    });
    this.boundsChanged$ = this.map.boundsObservable();
  }

  private assumeAltitude([x, y, z]: GeoPoint): [number, number, number] {
    return [x, y, z || this.minDisplayAltitude];
  }

  private assumeAltitudes(point: GeoPoint[]) {
    return point.map((p) => this.assumeAltitude(p));
  }

  private altitudeFactor() {
    return this.flattenAltitudes ? altitudeFlatteningFactor : 1;
  }

  private altitudeTransformMatrix() {
    const altitudeFactor = this.altitudeFactor();
    return new Matrix4()
      .scale([1, 1, altitudeFactor])
      .translate([0, 0, -this.altitudeOffset]);
  }

  private createInteractiveGeoJsonLayer() {
    const selectedIndex = this.displayMapElements.findIndex(
      (m) => m.id === this.selectedMapElement?.id,
    );

    return new InteractiveGeoJsonLayer({
      id: 'interactive-geojson-layer',
      mode: this.mode,
      data: {
        type: 'FeatureCollection',
        features: this.displayMapElements,
      },
      assumeAltitude: (point) => this.assumeAltitude(point),
      modelMatrix: this.altitudeTransformMatrix(),
      mapCoordGetter: (coords, context) =>
        this.surfaceCoordinates(coords, context),
      filled: false,
      onEdit: (editAction) => this.onEdit(editAction),
      selectedFeatureIndexes: selectedIndex >= 0 ? [selectedIndex] : [],
      getLineColor: (f: MapElement, isSelected, interactiveMode) =>
        getLineColor(f, isSelected, interactiveMode),
      getLineWidth: (f: MapElement) => (f.elementType === 'Node' ? 8 : 4),
      getHighlightColor: (pickingInfo, interactiveMode) =>
        getHighlightColor(pickingInfo, interactiveMode),
      getIcon,
      getIconSize: 20,
      pointType: 'circle+icon',
      getPointRadius: 4,
      lineJointRounded: true,
      opacity: this.mapElementOpacity,
      enableMapPanning: (enablePanning) => {
        this.map.enableDragPanning(enablePanning);
      },
      onHover: (pickingInfo) => {
        const hovering = Boolean(pickingInfo.object);

        if (hovering !== this.hoveringOverObject) {
          this.hoveringOverObject = hovering;
          const cursor = hovering ? 'pointer' : 'grab';
          this.map.setProps({ getCursor: () => cursor });
        }
      },
    });
  }

  private createCorridorPolygons(mapElement: MapElement) {
    const features = [];
    const props = mapElement.properties;
    if (
      props &&
      mapElement.geometry.type === 'LineString' &&
      'startWidth' in props &&
      'endWidth' in props &&
      (props.startWidth ?? 0) > 0 &&
      (props.endWidth ?? 0) > 0
    ) {
      const polygon = generateCorridorPolygon(
        mapElement.geometry.coordinates,
        props.startWidth!,
        props.endWidth!,
      );
      features.push({ polygon, color: getCorridorColor(mapElement) });
    }

    return features;
  }

  private createCorridorPolygonsLayer() {
    const polygons = this.displayMapElements.flatMap((m) =>
      this.createCorridorPolygons(m),
    );

    return new SolidPolygonLayer<(typeof polygons)[0]>({
      id: 'corridor-polygons',
      data: polygons,
      getFillColor: (f) => f.color,
      getPolygon: (f) => this.assumeAltitudes(f.polygon),
      modelMatrix: this.altitudeTransformMatrix(),
    });
  }

  private createDerivedLegendLayers(): Layer[] {
    const robotQueueEdges = this.displayMapElements.filter(
      (m) => m.elementType === ElementType.ROBOT_QUEUE_EDGE,
    );

    const ICON_MAPPING: IconMapping = {
      marker: {
        x: 0,
        y: 0,
        width: 48,
        height: 48,
        mask: true,
        anchorY: 48,
        anchorX: 24,
      },
    };
    const getPosition = ({
      geometry: { coordinates },
    }: EdgeElement): [number, number, number] => {
      const { length } = coordinates;
      const midIndex = length / 2;
      if (length % 2 === 0) {
        const startPoint = coordinates[midIndex - 1];
        const endPoint = coordinates[midIndex];
        return this.assumeAltitude(getMidPoint(startPoint, endPoint));
      } else {
        return this.assumeAltitude(coordinates[floor(midIndex)]);
      }
    };

    return [
      new ZoomBoundedLayer({
        id: 'zoom-bounds-queue-name-layer',
        minZoom: zoomThreshold,
        renderLayers: () => [
          new TextLayer({
            getSize: 12,
            id: 'queue-name-layer',
            data: robotQueueEdges,
            getPosition,
            getText: (d) => d?.properties?.names?.join('\n'),
            getAngle: 0,
            getTextAnchor: 'start',
            getAlignmentBaseline: 'bottom',
            getPixelOffset: [10, 0],
            background: true,
            getBackgroundColor: [255, 255, 255, 150],
            modelMatrix: this.altitudeTransformMatrix(),
          }),
        ],
      }),
      new ZoomBoundedLayer({
        id: 'zoom-bounds-queue-marker-layer',
        minZoom: 14,
        renderLayers: () => [
          new IconLayer({
            id: 'queue-marker-layer',
            data: robotQueueEdges,
            iconAtlas: 'assets/baseline_location_on_black_24dp.png',
            iconMapping: ICON_MAPPING,
            getIcon: () => 'marker',
            sizeScale: 15,
            getPosition,
            modelMatrix: this.altitudeTransformMatrix(),
          }),
        ],
      }),
    ];
  }

  private createOneWayArrowLayer() {
    const arrowElements = this.displayMapElements
      .filter(
        ({ elementType, properties, geometry }) =>
          [
            ElementType.ROBOT_QUEUE_EDGE,
            ElementType.ROAD_EDGE,
            ElementType.CACHED_ROAD_EDGE,
          ].includes(elementType) ||
          (geometry.type === 'LineString' &&
            properties &&
            'oneway' in properties &&
            properties.oneway),
      )
      .flatMap((element) =>
        generateOnewayArrows(
          this.assumeAltitudes(element.geometry.coordinates as GeoPoint[]),
        ).map((arrow) => ({
          ...arrow,
          color: getLineColor(element, false),
        })),
      );

    return new IconLayer<(typeof arrowElements)[number]>({
      id: 'arrows',
      data: arrowElements,
      getPosition: (a) => a.position,
      getColor: (a) => a.color,
      getIcon: () => 'chevron',
      getAngle: (a) => -a.heading,
      billboard: false,
      getSize: 1,
      sizeUnits: 'meters',
      sizeMinPixels: 12,
      sizeMaxPixels: 32,
      modelMatrix: this.altitudeTransformMatrix(),
      iconAtlas: this.icons.iconAtlas as any,
      iconMapping: this.icons.iconMapping,
    });
  }

  private createBlockedEdgesLayer() {
    const blockedEdges = this.displayMapElements
      .filter((e): e is EdgeElement => isEdgeBlocked(e))
      .flatMap((e) =>
        generateBlockedEdgePoints(this.assumeAltitudes(e.geometry.coordinates)),
      );

    return new IconLayer<(typeof blockedEdges)[number]>({
      id: 'blocked-edges',
      data: blockedEdges,
      getPosition: (a) => a,
      getIcon: () => 'forbiddenDirection',
      billboard: true,
      getSize: 2,
      sizeUnits: 'meters',
      sizeMinPixels: 10,
      sizeMaxPixels: 64,
      modelMatrix: new Matrix4()
        // shift up a litle so the icon doesn't intersect
        // the ground as much
        .translate([0, 0, 0.5])
        .multiplyRight(this.altitudeTransformMatrix()),
      iconAtlas: this.icons.iconAtlas as any,
      iconMapping: this.icons.iconMapping,
    });
  }

  private createSlippyTilesLayers() {
    const layers = [];
    for (const tiles of this.slippyTileMapElements) {
      const baseUrl = tiles.properties.tilesBaseUrl;
      const name = tiles.properties.name.toLowerCase();
      const opacity = this.layerStyles[name as LayerName]?.opacity ?? 0;
      layers.push(
        createTileLayer(
          `${baseUrl}/{z}/{x}/{y}.png`,
          baseUrl,
          16,
          zoomThreshold,
          256,
          opacity,
          tiles.geometry.coordinates[0],
        ),
      );
    }
    return layers;
  }

  private createLocalizationMapLayer(mapElement: LocalizationMapTiles): Layer {
    const altitudeScale = this.flattenAltitudes ? altitudeFlatteningFactor : 1;
    const urlPath = mapElement.properties.tilesBaseUrl.split('/').at(-1);
    const bounds = getBounds(mapElement.geometry.coordinates[0]);
    bounds[0] = mapElement.properties.tilesOriginLongitude;
    bounds[1] = mapElement.properties.tilesOriginLatitude;

    return new TileLayer({
      id: `localization-map-${urlPath}`,
      TilesetClass: LocalizationTileset,
      data: mapElement.properties.tilesBaseUrl,
      minZoom: zoomThreshold,
      tileSize: mapElement.properties.tilesSize,
      maxRequests: 6, // Concurrent requests to make loading faster.
      extent: bounds,
      pickable: true,
      maxCacheSize: 100,
      zRange: [0, this.maxDisplayAltitude - this.altitudeOffset],
      renderSubLayers: (props) => {
        return new LocalizationMapTileLayer(props, {
          tileIndex: [props.tile.index.x, props.tile.index.y],
          data: mapElement.properties,
          minDisplayAltitude: this.minDisplayAltitude,
          maxDisplayAltitude: this.maxDisplayAltitude,
          layerOpacities: this.getLayerOpacities(),
          modelMatrix: this.altitudeTransformMatrix(),
        });
      },
      getTileData: () => {},
      onTileError: (e) => {},
      onTileUnload: (tile) => {
        console.log('Unloading', tile.index.x, tile.index.y);
      },
      updateTriggers: {
        renderSubLayers: [
          this.minDisplayAltitude,
          this.maxDisplayAltitude,
          altitudeScale,
          this.altitudeOffset,
        ],
      },
    });
  }

  private createLocalizationMapLayers(): Layer[] {
    return this.localizationMapElements
      .filter(isDefined)
      .map((m) => this.createLocalizationMapLayer(m));
  }

  private surfaceCoordinates(
    screenCoords: [number, number],
    context: LayerContext,
  ): GeoPoint {
    const pick = context.deck?.pickObject({
      x: screenCoords[0],
      y: screenCoords[1],
      unproject3D: true,
      layerIds: ['localization-map'],
    });

    const heightMapOffset = 0.1;

    if (pick?.coordinate?.length && (pick as any)?.altitude !== undefined) {
      return [
        pick.coordinate[0],
        pick.coordinate[1],
        (pick as any).altitude + heightMapOffset,
      ];
    }

    const [lng, lat] = context.viewport.unproject(screenCoords);
    return [lng, lat];
  }

  getLatLngBounds(): LatLngBounds {
    return this.map.getBounds();
  }

  getZoom(): number {
    return this.map.getZoom();
  }

  fitBounds(bounds: LatLngBounds) {
    this.map.fitBounds(bounds);
  }

  setMode(mode: InteractiveMode) {
    this.mode = mode;
    this.rerenderMapElements();
  }

  enableTerrain(enabled: boolean) {
    this.map.enableTerrain(enabled);
  }

  enableAltitudeFlattening(enabled: boolean) {
    this.map.enableTerrain(false);
    this.flattenAltitudes = enabled;
    this.updateDisplayMapElements();
  }

  setDisplayAltitudeRange(minAltitude: number, maxAltitude: number) {
    this.minDisplayAltitude = minAltitude;
    this.maxDisplayAltitude = maxAltitude;
    this.updateDisplayMapElements();
  }

  setMapStyle(tileStyle: MapStyle) {
    this.map.setMapStyle(tileStyle);
  }

  setSelectedMapElement(mapElement?: MapElement) {
    this.selectedMapElement = mapElement;
    this.rerenderMapElements();
  }

  setHiddenMapElementTypes(hiddenElementTypes: ElementType[]) {
    if (
      hiddenElementTypes.length === this.hiddenMapElementTypes.length &&
      hiddenElementTypes.every((v) => this.hiddenMapElementTypes.includes(v))
    ) {
      return;
    }
    this.hiddenMapElementTypes = hiddenElementTypes;
    this.updateDisplayMapElements();
  }

  setMapElements(mapElements: MapElement[]) {
    const existingMapElements = mapElements.filter((m) => !m.deleted);
    const slippyTiles = [];
    const localizationMapElements = [];
    const interactiveMapElements = [];
    for (const element of existingMapElements) {
      if (element.elementType === ElementType.SLIPPY_TILES) {
        slippyTiles.push(element);
      } else if (element.elementType === ElementType.LOCALIZATION_MAP_TILES) {
        localizationMapElements.push(element);
      } else {
        interactiveMapElements.push(element);
      }
    }

    this.slippyTileMapElements = slippyTiles;
    this.slippyTileMapElements.sort((a, b) => {
      const z1 =
        this.layerStyles[a.properties.name.toLowerCase() as LayerName]
          ?.zIndex ?? 0;
      const z2 =
        this.layerStyles[b.properties.name.toLowerCase() as LayerName]
          ?.zIndex ?? 0;
      return z1 - z2;
    });

    this.localizationMapElements = localizationMapElements;
    this.interactiveMapElements = interactiveMapElements;

    let minAltitude = Infinity;
    let maxAltitude = -Infinity;
    const updateMinMaxAltitude = (altitude?: number) => {
      if (altitude) {
        minAltitude = min(minAltitude, altitude);
        maxAltitude = max(maxAltitude, altitude);
      }
    };
    for (const m of interactiveMapElements) {
      switch (m.geometry.type) {
        case 'Point':
          updateMinMaxAltitude(m.geometry.coordinates[2]);
          break;
        case 'LineString':
          for (const c of m.geometry.coordinates) {
            updateMinMaxAltitude(c[2]);
          }
          break;
        case 'Polygon':
          for (const c of m.geometry.coordinates[0]) {
            updateMinMaxAltitude(c[2]);
          }
          break;
      }
    }
    for (const m of this.localizationMapElements) {
      if (m.properties.minAltitude) {
        minAltitude = min(minAltitude, m.properties.minAltitude);
      }
      if (m.properties.maxAltitude) {
        maxAltitude = max(maxAltitude, m.properties.maxAltitude);
      }
    }
    if (!isFinite(minAltitude)) {
      minAltitude = 0;
    }
    if (!isFinite(maxAltitude)) {
      maxAltitude = minAltitude;
    }
    this.altitudeOffset = minAltitude;
    this._altitudeRange$.next([floor(minAltitude), ceil(maxAltitude + 1)]);
    this.updateDisplayMapElements();
  }

  private onEdit(editAction: EditAction) {
    const change: MapElement[] = [];
    for (
      let i = 0;
      i < (editAction.editContext?.featureIndexes?.length ?? 0);
      ++i
    ) {
      const index = editAction.editContext?.featureIndexes[i];
      const mapElement = editAction.updatedData.features[index] as MapElement;
      this.displayMapElements[index] = mapElement;
      change.push(mapElement);
    }

    // change array identity so DeckGL picks up the edits
    this.displayMapElements = [...this.displayMapElements];
    this.rerenderMapElements();

    if (
      editAction.editType === 'addFeature' ||
      editAction.editType === 'removeFeature' ||
      editAction.editType === 'finishMovePosition' ||
      editAction.editType === 'addPosition' ||
      editAction.editType === 'removePosition'
    ) {
      this._onChange$.next(change);
    }
  }

  rerenderMapElements() {
    const layers: Layer[] = [
      ...this.createSlippyTilesLayers(),
      ...this.createLocalizationMapLayers(),
      this.createCorridorPolygonsLayer(),
      this.createOneWayArrowLayer(),
      this.createInteractiveGeoJsonLayer(),
      this.createBlockedEdgesLayer(),
      ...this.createDerivedLegendLayers(),
    ];
    this.map.setProps({ layers });
  }

  setMapElementOpacity(opacity: number) {
    if (opacity === this.mapElementOpacity) {
      return;
    }
    this.mapElementOpacity = opacity;
    this.rerenderMapElements();
  }

  getMapElementOpacity(): number {
    return this.mapElementOpacity;
  }

  setLayerOpacities(layerOpacities: Record<LayerName, number>) {
    let changed = false;
    for (const [layerName, opacity] of Object.entries(layerOpacities)) {
      const prev = this.layerStyles[layerName as LayerName].opacity;
      this.layerStyles[layerName as LayerName].opacity = opacity;
      changed ||= prev !== opacity;
    }
    if (changed) {
      this.rerenderMapElements();
    }
  }

  getLayerOpacities(): Record<LayerName, number> {
    return Object.fromEntries(
      Object.entries(this.layerStyles).map(([layerName, { opacity }]) => [
        layerName,
        opacity,
      ]),
    ) as Record<LayerName, number>;
  }

  private isWithinDisplayAltitudeRange(m: MapElement): boolean {
    const coordWithinDisplayAltitudeRange = (coord: GeoPoint) =>
      !coord[2] ||
      (coord[2] <= this.maxDisplayAltitude &&
        coord[2] >= this.minDisplayAltitude);

    switch (m.geometry.type) {
      case 'Point':
        return coordWithinDisplayAltitudeRange(m.geometry.coordinates);
      case 'LineString':
        return m.geometry.coordinates.some((c) =>
          coordWithinDisplayAltitudeRange(c),
        );
      case 'Polygon':
        return m.geometry.coordinates[0].some((c) =>
          coordWithinDisplayAltitudeRange(c),
        );
      default:
        return false;
    }
  }

  private updateDisplayMapElements() {
    const hiddenElementTypes = new Set(this.hiddenMapElementTypes);
    if (this.zoom <= zoomThreshold) {
      hiddenElementTypes.add(ElementType.NODE);
      hiddenElementTypes.add(ElementType.TRAFFIC_LIGHT);
      hiddenElementTypes.add(ElementType.MUTEX);
      hiddenElementTypes.add(ElementType.APRIL_TAG);
      hiddenElementTypes.add(ElementType.HANDOVER_LOCATION);
      hiddenElementTypes.add(ElementType.INFRASTRUCTURE);
    }

    const isHidden = (m: MapElement) => hiddenElementTypes.has(m.elementType);

    this.displayMapElements = this.interactiveMapElements.filter(
      (m) => !isHidden(m) && this.isWithinDisplayAltitudeRange(m),
    );

    this.rerenderMapElements();
  }
}
