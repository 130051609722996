<div class="selection-container" [class.active-selection-container]="active">
  <div class="selection-insides" (click)="setActive(true)">
    @if (active) {
      <input
        #selectionInput
        type="text"
        [value]="searchString"
        (input)="filterOptions($event.target.value)"
        (focus)="active = true"
        (blur)="browseOptions === false && (active = false)"
        class="selection-input"
      />
    } @else {
      <div class="placeholder">
        <div>{{ selected ?? label }}</div>
        <mat-icon>arrow_drop_down</mat-icon>
      </div>
    }
  </div>
  @if (active) {
    <div
      (mouseenter)="browseOptions = true"
      (mouseleave)="browseOptions = false"
      class="options"
    >
      @for (option of filteredOptions; track option) {
        <div class="options-item" (click)="selectOption(option)">
          {{ option }}
        </div>
      }
    </div>
  }
</div>
