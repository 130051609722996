import {
  enableProdMode,
  ErrorHandler,
  APP_INITIALIZER,
  importProvidersFrom,
} from '@angular/core';

import * as Sentry from '@sentry/angular';
import type { Integration } from '@sentry/types';

import { environment } from './environments/environment';
import { getBuildHash } from './buildHash';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { AppComponent } from './app/app.component';
import { AppRoutingModule } from './app/app-routing.module';
import { AuthGuard } from './app/core/auth.guard';
import { AuthInterceptor } from './app/core/auth.interceptor';
import { AuthService } from './app/core/auth.service';
import { BackendService } from './app/core/backend.service';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { ClockingService } from './app/core/clocking/clocking.service';
import { CrossingMonitoringLocalStateService } from './app/crossing-monitoring/crossing-monitoring-local-state.service';
import { DataViewerService } from './app/ml-platform/data-viewer/data-viewer.service';
import { DataViewerStateService } from './app/ml-platform/data-viewer/data-viewer-state.service';
import { DeadmanSwitchService } from './app/supervised-robots/deadman-switch/deadman-switch.service';
import { EmergencyStopActionService } from './app/operation-management/emergency-stop-action.service';
import { EnableExtraSlotsService } from './app/supervised-robots/enable-extra-slots.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LeafletMapStateService } from './app/core/leaflet-map/leaflet-map-state.service';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTableExporterModule } from 'mat-table-exporter';
import { MetricsService } from './app/core/metrics/metrics.service';
import { MiniMapStateService } from './app/core/mini-map/mini-map-state.service';
import { MlActionService } from './app/ml-platform/ml-action.service';
import { MlBackendService } from './app/core/ml-backend.service';
import { MlDataService } from './app/ml-platform/ml-data.service';
import { RobotLastStateService } from './app/core/robots-service/robot-last-state.service';
import { RobotOperatorOverviewDisplayStateService } from './app/robot-operator-overview/robot-operator-overview-display-state.service';
import { RobotsBackendService } from './app/core/robots-service/robots-backend.service';
import { RobotsService } from './app/core/robots-service/robots.service';
import { Router, RouterModule } from '@angular/router';
import { ServerUpdatesService } from './app/core/server-updates.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SignalingService } from './app/core/robots-service/webrtc/signaling.service';
import { SupervisedRobotSlotsConfigService } from './app/supervised-robots/supervised-robots-slots.service';
import { SupervisionSettingsService } from './app/supervised-robots/supervision-settings/supervision-settings.service';
import { TackingViewStateService } from './app/order-tracking/tracking-view/tracking-view-state.service';
import { UserEventService } from './app/core/user-session/user-events.service';
import { UserSessionConnectionService } from './app/core/user-session/user-session-connection.service';
import { UserSessionEventTrackingService } from './app/core/user-session/user-session-event-tracking.service';
import { UserSessionService } from './app/core/user-session/user-session.service';
import { UsersService } from './app/core/users.service';
import { WebSocketService } from './app/core/web-socket/web-socket.service';
import { provideAnimations } from '@angular/platform-browser/animations';

const integrations: Integration[] = [Sentry.browserTracingIntegration({})];

if (environment.sentry.replay)
  integrations.push(
    Sentry.replayIntegration({
      blockAllMedia: false,
      maskAllText: false,
    }),
  );

if (environment.sentry.enabled)
  Sentry.init({
    dsn: 'https://1d294c4164714b6aa072bcbbd806405e@o4505049490194432.ingest.sentry.io/4505049522044928',
    tunnel: environment.sentry.tunnel,
    environment: environment.name,
    release: getBuildHash(),
    integrations,

    tracesSampleRate: environment.sentry.tracing,
    replaysSessionSampleRate: environment.sentry.replaySessions,
    replaysOnErrorSampleRate: environment.sentry.replayErrors,
  });

if (environment.production) enableProdMode();

bootstrapApplication(AppComponent, {
  providers: [
    AuthGuard,
    AuthService,
    BackendService,
    ClockingService,
    CrossingMonitoringLocalStateService,
    DataViewerService,
    DataViewerStateService,
    DeadmanSwitchService,
    EmergencyStopActionService,
    EnableExtraSlotsService,
    LeafletMapStateService,
    MetricsService,
    MiniMapStateService,
    MlActionService,
    MlBackendService,
    MlDataService,
    RobotLastStateService,
    RobotOperatorOverviewDisplayStateService,
    RobotsBackendService,
    RobotsService,
    ServerUpdatesService,
    SignalingService,
    SupervisedRobotSlotsConfigService,
    SupervisionSettingsService,
    TackingViewStateService,
    UserEventService,
    UserSessionConnectionService,
    UserSessionEventTrackingService,
    UserSessionService,
    UsersService,
    WebSocketService,
    importProvidersFrom(
      AppRoutingModule,
      BrowserModule,
      FormsModule,
      MatNativeDateModule,
      MatTableExporterModule,
      ReactiveFormsModule,
      RouterModule,
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: environment.production,
        registrationStrategy: 'registerImmediately',
      }),
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      // This will add tracing automatically to modules of modules lazy loaded
      // https://angular.io/api/core/APP_INITIALIZER
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
  ],
}).catch((err) => console.error(err));
