<mat-dialog-content class="mat-typography">
  @if (isClaimed) {
    <div class="text-container">
      <h2>Robot claimed by {{ userClaim.claimedBy }}</h2>
      since {{ userClaim.claimedSince | date: 'medium' }} <br />
      @if (userClaim.claimedUntil) {
        <h2>until {{ userClaim.claimedUntil | date: 'medium' }}</h2>
      }
      @if (userClaim.reason) {
        <h2>because: {{ userClaim.reason }}</h2>
      }
      <br />
    </div>
  }

  <div class="form-fields">
    <h1>Claim Robot</h1>
    <mat-form-field appearance="fill">
      <mat-label>Claimed by</mat-label>
      <input matInput placeholder="Name" [(ngModel)]="claimedByInput" />
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>What for? (optional)</mat-label>
      <input matInput placeholder="Reason" [(ngModel)]="reasonInput" />
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>How long [hours]? (optional)</mat-label>
      <input
        matInput
        type="number"
        placeholder="hours"
        min="0"
        [(ngModel)]="forHowLongInput"
        (change)="updateClaimedUntilInput()"
      />
    </mat-form-field>
    @if (untilInput) {
      <span> Claiming until {{ untilInput | date: 'short' }}</span>
    }
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    mat-button
    mat-dialog-close
    [disabled]="!isClaimed"
    (click)="unclaimRobot()"
  >
    Unclaim
  </button>

  <button
    mat-raised-button
    mat-dialog-close
    (click)="claimRobot()"
    [disabled]="isClaimed || !claimedByInput"
  >
    Claim
  </button>
</mat-dialog-actions>
