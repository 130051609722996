import { Component, OnDestroy, OnInit } from '@angular/core';
import { DataItem, DataViewerService } from './data-viewer.service';
import { Observable, Subject, merge, throttleTime } from 'rxjs';
import { DataViewerTabName } from './data-viewer-state.service';
import { DataGridComponent } from '../shared/data-grid.component';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-data-viewer-grid',
  templateUrl: './data-viewer-grid.component.html',
  styleUrls: ['./data-viewer-grid.component.sass'],
  standalone: true,
  imports: [DataGridComponent, AsyncPipe],
})
export class DataViewerGridComponent implements OnDestroy {
  private _destroy$ = new Subject<void>();
  selectedItemId$: Observable<number | undefined>;
  selectedDataViewerTab$: Observable<DataViewerTabName>;

  constructor(readonly dataViewerService: DataViewerService) {
    this.selectedItemId$ = this.dataViewerService.selectedItemId$;
    this.selectedDataViewerTab$ = this.dataViewerService.selectedDataViewerTab$;
  }

  selectItem(item: DataItem) {
    this.dataViewerService.selectItemId(item);
  }

  pickItem(item: DataItem) {
    this.dataViewerService.toggleItemPick(item);
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  async nextPage() {
    this.dataViewerService.getNextPage();
  }
}
