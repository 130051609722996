import { Component, Input } from '@angular/core';

import { MatIcon } from '@angular/material/icon';
import { DurationPipe } from '../../../../core/pipes/duration.pipe';

@Component({
  selector: 'app-robot-blocking',
  templateUrl: './robot-blocking.component.html',
  styleUrls: ['./robot-blocking.component.sass'],
  standalone: true,
  imports: [MatIcon, DurationPipe],
})
export class RobotBlockingComponent {
  @Input()
  blockedByCurrentUserForMillis: number | null = null;
}
