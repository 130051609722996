<div class="robot-control-panel-container">
  @if (isClaimControlEnabled) {
    <mat-slide-toggle
      class="mat-slide-toggle-margin"
      [ngModel]="robotCommunication?.isInControl$ | async"
      (change)="setInControl($event.checked, $event)"
      >Claim Control</mat-slide-toggle
    >
  }
  @if (isManualControlEnabled) {
    <mat-slide-toggle
      class="mat-slide-toggle-margin"
      [ngModel]="robotCommunication?.isManualMouseControl$ | async"
      [disabled]="isNotInControl$ | async"
      (change)="enableManualMouseControl($event.checked)"
      >Manual Control</mat-slide-toggle
    >
  }

  @if (autonomyControlType === 'AUTONOMY_TOGGLE') {
    <mat-slide-toggle
      class="mat-slide-toggle-margin"
      [ngModel]="this.robotCommunication?.isAutonomyEnabled$ | async"
      (change)="toggleIsAutonomyEnabled()"
      [disabled]="isNotInControl$ | async"
      >Autonomy</mat-slide-toggle
    >
  }

  @if (isCurbClimbingControlEnabled) {
    <mat-slide-toggle
      class="mat-slide-toggle-margin"
      [ngModel]="this?.robotCommunication?.isCurbClimbingOn$ | async"
      [disabled]="isNotInControl$ | async"
      (change)="this.robotCommunication?.enableCurbClimbingMode($event.checked)"
      >Curb Climbing</mat-slide-toggle
    >
  }

  @if (isReadyForOrderControlEnabled) {
    <mat-slide-toggle
      class="mat-slide-toggle-margin"
      [checked]="robotCommunication?.readyForOrders$ | async"
      (change)="setRobotReadyForOrder($event.checked)"
      [disabled]="isNotInControl$ | async"
      >Ready For Orders</mat-slide-toggle
    >
  }

  @if (hazardLightControlType === 'HAZARD_LIGHTS_DROPDOWN') {
    <mat-form-field class="robotCommunication-form-menu">
      <mat-label>Hazard Lights</mat-label>
      <mat-select
        [ngModel]="hazardLightState$ | async"
        [disabled]="isNotInControl$ | async"
        (selectionChange)="sendLightingCommand($event.value)"
      >
        @for (state of HAZARD_LIGHTS_STATES; track state) {
          <mat-option [value]="state">
            {{ state }} ({{ hazardLightState$ | async }})
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  }

  @if (isNetworkInterfaceControlEnabled) {
    <mat-form-field class="robotCommunication-form-menu">
      <mat-label>Network Interface</mat-label>
      <mat-select
        (selectionChange)="selectNetworkInterface($event.value)"
        [value]="robotCommunication?.selectedNetworkInterface$ | async"
      >
        @for (
          iface of robotCommunication?.availableNetworkInterfaces$ | async;
          track iface.interfaceName
        ) {
          <mat-option [value]="iface.interfaceName">
            @if (iface.interfaceName) {
              <span
                >{{ iface.interfaceDisplayName || iface.interfaceName }}
                @if (iface.minPing) {
                  <span>
                    ({{ iface.minPing | number: '1.1-1' }}&lt;{{
                      iface.avgPing | number: '1.1-1'
                    }}&lt;{{ iface.maxPing | number: '1.1-1' }}ms |
                    {{ iface.packageLoss | number: '1.0-0' }}% )</span
                  >
                }
              </span>
            }

            @if (!iface.interfaceName) {
              <span>No Preference</span>
            }
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  }

  @if (hazardLightControlType === 'HAZARD_LIGHT_TOGGLE') {
    <button
      class="mat-button-aspect-ratio"
      [disabled]="isNotInControl$ | async"
      mat-mini-fab
      [matTooltip]="'Enable hazard lights'"
      color="primary"
      (click)="toggleHazardLightMode()"
      [class.on-mode]="(hazardLightState$ | async) !== 'Auto'"
    >
      <mat-icon>online_prediction</mat-icon>
    </button>
  }

  @if (isLidClockControlEnabled && legacyLidControlActive) {
    <button
      class="mat-button-aspect-ratio"
      [disabled]="isNotInControl$ | async"
      mat-mini-fab
      [matTooltip]="isLocked ? 'Unlock' : 'Lock'"
      color="primary"
      (click)="toggleLock()"
      [class.on-mode]="!isLocked"
    >
      @if (isLocked) {
        <mat-icon>lock_open</mat-icon>
      }
      @if (!isLocked) {
        <mat-icon>lock</mat-icon>
      }
    </button>
  }

  @if (isCompartmentControlEnabled && !legacyLidControlActive) {
    <button
      class="mat-button-aspect-ratio"
      [disabled]="isNotInControl$ | async"
      mat-mini-fab
      [matTooltip]="'Handle Compartments'"
      color="primary"
      (click)="openCompartmentsDialog()"
    >
      <mat-icon>widgets</mat-icon>
    </button>
  }

  @if (autonomyControlType === 'PARKING_MODE') {
    <button
      class="mat-button-aspect-ratio"
      [disabled]="isNotInControl$ | async"
      mat-mini-fab
      [matTooltip]="'Parking mode'"
      color="primary"
      (click)="toggleIsAutonomyEnabled()"
      [class.on-mode]="!(robotCommunication?.isAutonomyEnabled$ | async)"
    >
      <mat-icon>local_parking</mat-icon>
    </button>
  }

  @if (isOverlayMapControlEnabled) {
    <button
      class="mat-button-aspect-ratio"
      mat-mini-fab
      [matTooltip]="'Enable overlay map'"
      color="primary"
      (click)="toggleIsOverlayMapEnabled()"
      [class.on-mode]="robotCommunication?.isOverlayMapEnabled$ | async"
    >
      <mat-icon>map</mat-icon>
    </button>
  }

  @if (isBlockageReportEnabled) {
    <button
      class="mat-button-aspect-ratio"
      mat-mini-fab
      [matTooltip]="'Report blockage'"
      color="primary"
      (click)="openReportBlockageDialog()"
    >
      <mat-icon>block</mat-icon>
    </button>
  }

  @if (isArrivedControlEnabled) {
    <button
      class="mat-button-aspect-ratio"
      [disabled]="!(robotCommunication?.enableArrivedButton$ | async)"
      mat-mini-fab
      color="primary"
      [matTooltip]="'Arrived'"
      (click)="arrivedAtStop()"
    >
      <mat-icon>flag</mat-icon>
    </button>
  }

  @if (isSnapshotControlEnabled) {
    <button
      class="mat-button-aspect-ratio"
      mat-mini-fab
      color="primary"
      [matTooltip]="'Take a snapshot'"
      (click)="triggerSnapshot()"
    >
      <mat-icon>add_a_photo</mat-icon>
    </button>
  }
  @if (
    isPowerSavingControlEnabled && (isNotAutonomicPowerSavingControl$ | async)
  ) {
    <button
      class="mat-button-aspect-ratio"
      mat-mini-fab
      color="primary"
      [matTooltip]="'Toggle power saving mode'"
      [disabled]="isNotInControl$ | async"
      (click)="togglePowerSaving()"
      [class.on-mode]="robotCommunication?.isPowerSaving$ | async"
    >
      <mat-icon>bolt</mat-icon>
    </button>
  }

  @if (isAutomaticPowerControlEnabled) {
    <button
      class="mat-button-aspect-ratio"
      mat-mini-fab
      color="primary"
      [matTooltip]="'Toggle automatic power saving'"
      [disabled]="isNotInControl$ | async"
      (click)="toggleAutomaticPowerSaving()"
      [class.on-mode]="robotCommunication?.isAutomaticPowerSaving$ | async"
    >
      <mat-icon> energy_savings_leaf</mat-icon>
    </button>
  }

  @if (isHighBeamsControlEnabled) {
    <button
      class="mat-button-aspect-ratio"
      mat-mini-fab
      color="primary"
      [matTooltip]="'Toggle High Beams'"
      [disabled]="isNotInControl$ | async"
      (click)="toggleHighBeams()"
      [class.on-mode]="isHighBeamsEnabled"
    >
      <mat-icon>highlight</mat-icon>
    </button>
  }

  @if (isSirenControlEnabled) {
    <button
      class="mat-button-aspect-ratio"
      mat-mini-fab
      color="primary"
      [matTooltip]="'Toggle Siren'"
      [disabled]="isNotInControl$ | async"
      [class.on-mode]="isSirenOn"
      (click)="toggleSiren()"
    >
      <mat-icon>notification_important</mat-icon>
    </button>
  }

  @if (
    isIssueReportingEnabled && robotCommunication?.isAutonomyEnabled$ | async
  ) {
    <button
      class="mat-button-aspect-ratio"
      mat-mini-fab
      color="primary"
      matTooltip="Please STOP the robot before submitting an issue!"
      disabled
    >
      <mat-icon>priority_high</mat-icon>
    </button>
  }

  @if (
    isIssueReportingEnabled && !(robotCommunication?.isAutonomyEnabled$ | async)
  ) {
    <button
      class="mat-button-aspect-ratio"
      mat-mini-fab
      color="primary"
      [disabled]="robotCommunication?.isAutonomyEnabled$ | async"
      matTooltip="Submit an Issue"
      (click)="openRobotIssue()"
    >
      <mat-icon>priority_high</mat-icon>
    </button>
  }
  @if (robotSoundAvailable$ | async) {
    <div class="robot-audio">
      @if (!(audioLevelActive$ | async)) {
        <button
          class="mat-button-aspect-ratio"
          mat-mini-fab
          color="primary"
          [matTooltip]="
            (speakerActive$ | async) ? 'Disable Speaker' : 'Enable Audio Level'
          "
          [class.on-mode]="speakerActive$ | async"
          (click)="toggleRobotAudio()"
        >
          <mat-icon>campaign</mat-icon>
        </button>
      }

      @if (audioLevelActive$ | async) {
        <button
          class="mat-button-aspect-ratio"
          mat-mini-fab
          color="primary"
          [matTooltip]="'Enable Speaker'"
          [class.on-mode]="true"
          (click)="toggleRobotAudio()"
        >
          <mat-icon>equalizer</mat-icon>
        </button>
      }

      @if (audioLevelActive$ | async) {
        <div class="audio-plot">
          <app-audio-level-overlay
            [robotCommunication]="robotCommunication"
            class="audio-button-plot"
          >
          </app-audio-level-overlay>
        </div>
      }
    </div>
  }

  @if (isNavigationToMaintenanceEnabled) {
    <button
      class="mat-button-aspect-ratio"
      mat-mini-fab
      color="primary"
      [matTooltip]="'Open in maintenance mode'"
      (click)="openMaintenanceUi()"
    >
      <mat-icon>build</mat-icon>
    </button>
  }

  @if (isRelocalizeEnabled) {
    <button
      class="mat-button-aspect-ratio"
      mat-mini-fab
      color="primary"
      [disabled]="isNotInControl$ | async"
      [matTooltip]="'Relocalize'"
      (click)="relocalize()"
    >
      <mat-icon>mode_of_travel</mat-icon>
    </button>
  }

  @if (isCalibrateEndstopEnabled) {
    <button
      class="mat-button-aspect-ratio"
      mat-mini-fab
      color="primary"
      [disabled]="isNotInControl$ | async"
      [matTooltip]="'Calibrate Endstops / IMU'"
      (click)="calibrateEndstop()"
    >
      <mat-icon>play_for_work</mat-icon>
    </button>
  }
</div>
